<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <works-modal ref="detailsModal" :selectedData="selectedData" @GetList="GetList" />
    <MyDataTable
      title="انیمیشن های موجود"
      subtitle="مدیریت انیمیشن"
      addtitle="ایجاد انیمیشن جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>{{ row.title }}</td>
        <td>
          {{ row.user ? row.user.first_name + " " + row.user.last_name : "" }}
        </td>
        <td class="text-center">
          <v-chip
            v-if="row.status == 0"
            color="yellow darken-3"
            dark
            class="rounded"
            small
          >
            در حال بررسی
          </v-chip>
          <v-chip
            v-else-if="row.status == 1"
            color="success"
            dark
            class="rounded"
            small
          >
            تایید شده
          </v-chip>
          <v-chip v-else color="red" dark class="rounded" small>
            رد شده
          </v-chip>
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import WorksModal from "../../../../components/Panel/WorksModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    WorksModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/portfolios/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: ["media", "user"],
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/useranimations/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/portfolios/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/portfolios/index",
      pathParams: {
        with: ["media", "user"],
      },
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "title",
        },
        { text: "فروشنده", value: "user_id" },
        { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
